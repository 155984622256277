import {RouterProvider} from "react-router-dom";
import {router} from "../../routes";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';

function App() {
    return (
        <div className="App">
            <RouterProvider router={router} />
        </div>
      );
}

export default App;
