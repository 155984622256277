import React from 'react';
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import {ParallaxImage} from "../../components/parallax-image/parallax-image";
import {resourceLinks} from "./constants";
import ResourceCard from "../../components/resource-card/resource-card";

const ResourcesPage = () => {
    return (
        <PageWrapper>
            <ParallaxImage backgroundImage="linear-gradient(217deg, red, white 70.71%)" />
            <div className="hero-container px-4 py-5">
                <div className="col-md-6 mx-auto my-5" />
            </div>
            <div className="bg-red text-white container-fluid p-5">
                <h2 className="text-center">Other Resources:</h2>
                <div className="card-group">
                    {resourceLinks.map((resource, idx) => (
                        <ResourceCard key={`resource-link-${resource.name}`} resource={resource} />
                    ))}
                </div>
            </div>
        </PageWrapper>
    );
};

export default ResourcesPage;