export const MissionStatementTitle = 'Bellas Mission';

export const MissionStatement = [
    'Driving Hope, Swinging for a Cure - Empowering Lives through Golf for Vascular Ehlers Danlos Syndrome Awareness.',
    'At the heart of our mission lies an unwavering commitment to making a lasting impact on the lives affected by Vascular Ehlers Danlos syndrome (VEDS). We envision a world where those living with VEDS can enjoy healthier, fuller lives, free from the burdens of this rare and challenging condition. Our golf fundraiser, meticulously crafted with purpose and passion, seeks to raise awareness, foster community support, and generate critical funds exclusively dedicated to advancing research for a cure.',
    'Vascular Ehlers Danlos syndrome is a rare genetic disorder that affects individuals and families in profound ways. With fragility of blood vessels, organs, and tissues, those diagnosed with VEDS face daily challenges that require unwavering resilience. It is our mission to stand shoulder to shoulder with these brave individuals and their loved ones, empowering them with hope, and propelling medical science toward a breakthrough that will ultimately lead to a cure.',
    'Through Bellas Mission, we aim to unite individuals from all walks of life - athletes, healthcare professionals, philanthropists, and compassionate community members - to rally around our cause. Each swing of the club, each stroke, and each putt will resonate far beyond the greens, sending ripples of awareness across communities, cities, and even continents.',
    'As we gather on the fairways, we invite you to join us in this noble endeavor. Together, we can transform the landscape of Vascular Ehlers Danlos syndrome, steering the course toward a brighter future. With all proceeds directed unswervingly to research initiatives, we are dedicated to bolstering scientific advancements that bring us closer to eradicating this formidable adversary.',
    'Our golf fundraiser is not just a tournament; it is a declaration of compassion, a proclamation of solidarity, and a testament to the human spirit\'s boundless capacity for change. By aligning our collective efforts, we will drive awareness, swing for a cure, and empower lives, setting the stage for a world where VEDS is no longer a source of hardship, but a chapter of strength and resilience.',
    'Join us as we tee up for hope, and together, we will drive progress, inspire change, and make the dream of a VEDS-free world a reality.',
];