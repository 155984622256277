import React from 'react';
import {Navbar} from "../navbar/navbar";
import {Footer} from "../footer/footer";
import {ScrollRestoration} from "react-router-dom";

const PageWrapper = ({ children }) => {
    return (
        <>
            <Navbar />
            <div className="mt-3">
                {children}
            </div>
            <ScrollRestoration />
            <Footer />
        </>
    );
};

export default PageWrapper;