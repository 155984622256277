import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import logoSrc from "../../img/logo.png";

import './login-signup-form.css';
import { app } from "../../config/firebase";

const LoginSignupForm = ({ isLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onSuccess = useCallback((userCredential) => {
        console.log(userCredential);
    }, []);

    const onError = useCallback((error) => {
        console.log(error);
    }, []);

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        const auth = getAuth(app);
        if (isLogin) {
            signInWithEmailAndPassword(auth, email, password)
                .then(onSuccess)
                .catch(onError);
        } else {
            createUserWithEmailAndPassword(auth, email, password)
                .then(onSuccess)
                .catch(onError);
        }
    }, [isLogin, email, password, onSuccess, onError]);

    const onChangeEmail = useCallback((e) => {
        setEmail(e.target.value);
    }, [])

    const onChangePassword = useCallback((e) => {
        setPassword(e.target.value);
    }, [])

    return (
        <form className="col-md-6 justify-content-center text-center">
            <img className="mb-4" src={logoSrc} alt="" width="100" height="100" />
            {isLogin
                ? <h1 className="h3 mb-3 fw-normal">Please sign in</h1>
                : <h1 className="h3 mb-3 fw-normal">Registration</h1>
            }

            <div className="form-floating">
                <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" onChange={onChangeEmail} value={email}/>
                <label htmlFor="floatingInput">Email address</label>
            </div>
            <div className="form-floating">
                <input type="password" className="form-control" id="floatingPassword" placeholder="Password" onChange={onChangePassword} value={password}/>
                <label htmlFor="floatingPassword">Password</label>
            </div>

            {isLogin && (
                <div className="checkbox my-3">
                    <label>
                        <input type="checkbox" value="remember-me" className="m-2"/>
                        Remember me
                    </label>
                </div>
            )}
            {/*<div className="form-floating my-3">*/}
            {/*    {isLogin*/}
            {/*        ? <p>Don't have an account? <Link to="/register">Register</Link></p>*/}
            {/*        : <p>Already have an account? <Link to="/login">Login</Link></p>*/}
            {/*    }*/}
            {/*</div>*/}
            <button className="w-100 btn btn-lg btn-primary" onClick={onSubmit}>
                {isLogin
                    ? 'Sign In'
                    : 'Register'
                }
            </button>
        </form>
    );
};

LoginSignupForm.propTypes = {
  isLogin: PropTypes.bool,
};

export default LoginSignupForm;