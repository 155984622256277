import React from 'react';
import './resource-card.css';

const ResourceCard = ({ resource }) => {
    return (
        <div className="card col-md-3">
            <div className="card-body">
                <div className="center-img mb-2">
                    <img className="card-img max-height" src={resource.imgSrc} alt={resource.name} />
                </div>
                <h5 className="card-title">
                    <a href={resource.url} className="link-danger">{resource.name}</a>
                </h5>
                <div className="card-text">
                    {resource.description}
                </div>
            </div>
        </div>
    );
};

export default ResourceCard;