import React, {useLayoutEffect, useRef, useState} from 'react';

const LocationMap = () => {
    const mapRef = useRef(null);
    const [mapBoundingRect, setMapBoundingRect] = useState(null);

    useLayoutEffect(() => {
        if (!mapBoundingRect && mapRef.current?.getBoundingClientRect()) {
            setMapBoundingRect(mapRef.current?.getBoundingClientRect());
        }
    }, [mapBoundingRect]);

    return (
        <div className="col-md-6" ref={mapRef}>
            {mapBoundingRect && (
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3389.509651892799!2d-106.43129927063433!3d31.838343994153245!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86e750b5c6b5a0b5%3A0x7876fd13369c89!2sUnderwood%20Golf%20Complex!5e0!3m2!1sen!2sus!4v1691650259725!5m2!1sen!2sus"
                    style={{ border: 0, width: mapBoundingRect.width, height: 350 }}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Underwood Golf Complex - Google Map"
                />
            )}
        </div>
    );
};

export default LocationMap;