import vedsMovement from './img/VEDSMovement.png';
import fightVEDS from './img/FightVEDS.webp';
import reds4VEDS from './img/Reds4VEDS.webp';
import katieWrightPodcast from './img/KatieWrightPodcast.png'

export const resourceLinks = [
    {
        url: 'https://thevedsmovement.org/',
        name: 'The VEDS Movement',
        imgSrc: vedsMovement,
        description: 'The VEDS Movement mission is to save lives and improve the quality of life of individuals with Vascular Ehlers-Danlos Syndrome (VEDS).'
    },
    {
        url: 'https://www.fightveds.org/',
        name: 'Fight VEDS',
        imgSrc: fightVEDS,
        description: 'FIGHT vEDS is a non-profit organization dedicated to fighting and finding a treatment for vascular Ehlers-Danlos Syndrome.'
    },
    {
        url: 'https://www.reds4veds.org/',
        name: 'Reds4VEDS',
        imgSrc: reds4VEDS,
        description: 'REDS4VEDS is hosted by Annabelle\'s Challenge, a UK registered charity supporting families affected by vascular EDS across the globe.'
    },
    {
        url: 'https://podcasts.apple.com/us/podcast/staying-connected/id1379815261',
        name: 'Staying Connected - Podcast',
        imgSrc: katieWrightPodcast,
        description: 'I started Staying Connected as a way to connect with other people diagnosed with or impacted by Vascular Ehlers-Danlos Syndrome, or VEDS. I myself was diagnosed with VEDS in 2017, and meeting others with VEDS has changed how I handle my own diagnosis. In this podcast I connect with other people whose lives have been impacted by VEDS and related vascular and aortic connective tissue conditions, like Marfan and Loeys-Dietz, getting a chance to hear and share their stories!'
    }
]