import {faDollar, faGolfBallTee} from "@fortawesome/free-solid-svg-icons";
import {faCalendar} from "@fortawesome/free-regular-svg-icons";

export const donationLink = "//www.zeffy.com/donation-form/efebfe2b-d395-4073-ab22-304651b2039d"
export const registrationLink = '//www.zeffy.com/ticketing/ecce25f3-82c6-4465-9441-eb2f461e59f0';

export const sponsorshipTiers = [
    {
        name: 'Golf Sponsor',
        price: 5000,
    },
    {
        name: 'Prize Sponsor',
        price: 2000,
    },
    {
        name: 'Dinner Sponsor',
        price: 2000,
    },
    {
        name: 'Team Sponsor',
        price: 600,
    },
    {
        name: 'Hole Sponsor',
        price: 250,
    },
    {
        name: 'Individual Player Sponsor',
        price: 150,
    },
];

export const playerInformationBullets = [
    { icon: faGolfBallTee, text: 'Scramble Format' },
    { icon: faCalendar, text: 'Friday 4th October, 2024' },
    { icon: faDollar, text: '$150 per Player or $600 per Team *' },
]

export const vedsEmail = 'vedsgolftournament@gmail.com';
export const emailSubject = (isDonations) => encodeURIComponent(`[${isDonations ? 'Donations' : 'Volunteering'}] - Bella's Mission`);
export const emailBody = (isDonations) => `Hello%20Frances%2C%20%0D%0A%0D%0AMy%20name%20is%20JohnDoe%20and%20I%20am%20interested%20in%20${isDonations ? 'donating%20to' : 'volunteering%20for'}%20Bella's%20Mission.%0D%0A%0D%0AMy%20contact%20info%20is%3A%0D%0AName%20-%20%0D%0APhone%20-%20%0D%0AEmail%20-%20%0D%0A%0D%0AThank%20you%2C%0D%0A`;