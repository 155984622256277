import React from 'react';
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import {ParallaxImage} from "../../components/parallax-image/parallax-image";
import {aboutUsBody, whatWeSupportBody} from "./constants";
import bellaAboutUs from './img/bella-about.jpg'
import {MissionStatement, MissionStatementTitle} from "../homepage/bellas-mission-statement";

const AboutUs = () => {
    return (
        <PageWrapper>
            <ParallaxImage backgroundImage={`url(${bellaAboutUs})`}/>
            <div className="hero-container px-4 py-5">
                <div className="col-md-6 mx-auto my-5"/>
            </div>
            <div className="bg-white container-fluid">
                <div className="p-5">
                    <h1 className="text-red text-center">{MissionStatementTitle}</h1>
                    {MissionStatement.map((par, idx) => <p key={`statement-par-${idx}`}>{par}</p>)}
                </div>
            </div>
            <div className="bg-red text-white container-fluid p-5">
                <h2 className="text-center">What is VEDS?</h2>
                {aboutUsBody.map((par, idx) => <p key={`statement-par-${idx}`}>{par}</p>)}
            </div>
            <div className="bg-white container-fluid p-5">
                <h2 className="text-center text-red">What does Bella’s Mission support?</h2>
                {whatWeSupportBody.map((par, idx) => <p key={`statement-par-${idx}`}>{par}</p>)}
            </div>
        </PageWrapper>
    );
};

export default AboutUs;