import React from 'react';
import './parallax-image.css';

export const ParallaxImage = ({ backgroundImage }) => {
    return (
        <div
            className="parallax-image"
            style={{backgroundImage}}
        />
    );
};

