import React from 'react';
import classNames from "classnames";
import {Link, useLocation} from "react-router-dom";

import {mainRoutes, userRoutes} from "../../routes";
import logoSrc from '../../img/logo.png';

const NavItem = ({ route, className }) => {
    const { path } = useLocation();
    const isActive = route.path === path;
    return (
        <li className="nav-item">
            <Link
                className={classNames("nav-link", {
                    active: isActive,
                    disabled: route.isDisabled,
                })}
                aria-current={isActive}
                to={route.path}
            >
                {route.name}
            </Link>
        </li>
    )
};

export const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-light">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <img src={logoSrc} height="30" width={30} alt="Bellas Mission" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarToggler">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {mainRoutes.map((route, idx) => (
                            <NavItem key={`main-route-${idx}`} route={route} />
                        ))}
                    </ul>
                    {/*<ul className="d-flex navbar-nav">*/}
                    {/*    {userRoutes.map((route, idx) => (*/}
                    {/*        <NavItem key={`user-route-${idx}`} route={route} />*/}
                    {/*    ))}*/}
                    {/*</ul>*/}
                </div>
            </div>
        </nav>
    );
};
