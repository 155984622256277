import React from 'react';
import PropTypes from 'prop-types';

const SponsorshipCard = ({ tier }) => {
    return (
        <div className={`col-md-2`}>
            <div className="card-body text-center">
                <div className="card-title">
                    {tier.name}
                </div>
                <div className="card-subtitle mb-2">
                    ${tier.price}
                </div>
            </div>
        </div>
    );
};

SponsorshipCard.propTypes = {
    tier: PropTypes.shape({
        classNames: PropTypes.string,
        name: PropTypes.string,
        price: PropTypes.number,
        description: PropTypes.string,
    })
};

export default SponsorshipCard;