import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AddToCalendarButton} from "add-to-calendar-button-react";
import {playerInformationBullets, registrationLink} from "../../constants";

export const PlayerInformation = props => {
    return (
        <div className="col-md-6">
            <h2 className="text-center">Player Information</h2>
            <hr/>
            {playerInformationBullets.map(({text, icon}, idx) => (
                <h5 key={`player-information-${idx}`}>
                    <FontAwesomeIcon icon={icon}/>
                    <span className="mx-4">{text}</span>
                </h5>
            ))}
            <p>* Price includes cart, complimentary alcoholic beverages, snacks, and dinner.</p>
            <div className="d-grid mx-auto d-sm-block">
                <a className="btn btn-outline-light btn-lg m-2" href={registrationLink} target='_blank'>
                    Register Player
                </a>
            </div>
            <h4 className="my-4">Schedule:</h4>
            <ul>
                <li>10:30am Registration</li>
                <li>12:00pm Shotgun Start</li>
                <li>5:00pm Dinner</li>
                <li>6:00pm Raffle</li>
            </ul>
            <div className="col d-inline-flex">
                <AddToCalendarButton
                    name="Bellas Mission Golf Tournament"
                    description="Thank you for saving the date to raise awareneess for Vascular Ehlers Danlnos syndrome(VEDs)"
                    startDate="2024-10-4"
                    startTime="10:30"
                    endTime="18:00"
                    timeZone="America/Denver"
                    location="3200 Coe Ave, El Paso, TX 79904"
                    options="'Apple','Google', 'iCal','Outlook.com','Yahoo'"
                    iCalFileName="Bellas Mission Golf Tournament iCal File"
                />
            </div>
        </div>
    );
};
