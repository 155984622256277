import React from 'react';
import faFacebook from './img/facebook.svg';
import faInstagram from './img/instagram.svg';
import {vedsEmail} from "../../constants";

import './footer.css';

export const Footer = () => {
    return (
        <div className="container-fluid">
            <p className="text-center">Follow Us on Social Media:</p>
            <div className="d-grid-inline text-center mx-auto">
                <a href="https://www.facebook.com/theinvedstigator">
                    <img className="svg-social" src={faFacebook} alt="Link to Facebook"/>
                </a>
                <a href="https://instagram.com/in_veds_tigator">
                    <img className="svg-social" src={faInstagram} alt="Link to Instagram"/>
                </a>
            </div>
            <div className="row p-5">
                <p className="text-center">For questions or concerns please reach out to: <a
                    href={`mailto:${vedsEmail}`}>{vedsEmail}</a></p>
            </div>
        </div>
    );
};

