import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getFirestore } from "firebase/firestore/lite"
// import { getAuth } from "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyA_DyKu2bxhkE-kCQUqN8tAH98Ls5AyWtU",
    authDomain: "bellas-mission.firebaseapp.com",
    projectId: "bellas-mission",
    storageBucket: "bellas-mission.appspot.com",
    messagingSenderId: "1092088117534",
    appId: "1:1092088117534:web:9c94d4138b7e58f955f10f",
    measurementId: "G-H20DK4B6HW"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);
