import HomePage from "./pages/homepage/home-page";
import {createBrowserRouter} from "react-router-dom";
import {Login} from "./pages/login/login";
import {Register} from "./pages/register/register";
import AboutUs from "./pages/about-us/about-us";
import Resources from "./pages/resources/resources";

export const userRoutes = [
    {
        path: '/login',
        name: 'Login',
        element: <Login />
    },
    {
        path: '/register',
        name: 'Register',
        element: <Register />,
        isDisabled: true,
    }
]

export const mainRoutes = [
    {
        path: '/',
        element: <HomePage />,
        name: 'Home'
    },
    {
        path: '/about-us',
        element: <AboutUs />,
        name: 'About Us'
    },
    {
        path: '/resources',
        name: 'Resources',
        element: <Resources />
    },
];

export const router = createBrowserRouter([
    {
        path: '/',
        element: <HomePage />,
    },
    ...mainRoutes,
    // ...userRoutes,
]);
