export const aboutUsBody = [
    'Vascular Ehlers Danlos Syndrome (VEDS, previously EDS type 4) is a rare and life-threatening genetic syndrome caused by genetic variants (mutations) in the Type 3 pro collagen gene (COL3A1). VEDS is a distinct and specific subtype of EDS that is completely different from the most common subtype, hypermobile EDS. The pathogenic variants in the COL3A1 gene cause the Type 3 collagen to be built abnormally and results in weakened tissues. Type 3 collagen is found in high quantities in our skin, hollow organs, and blood vessels.',
    'Patients who have VEDS are at risk for spontaneous aortic and arterial dissection, aneurysm rupture, hollow organ rupture (intestines, spleen, uterus), and carotid cavernous sinus fistula. All of these are life-threatening complications and can occur at any age. The current median life expectancy is 51 years old. ',
    'Other symptoms include easy and severe bruising and bleeding, skin that tears easily, thin skin, translucent skin that shows visible veins (like a roadmap), small joint flexibility (like the fingers), spontaneous lung collapse, and tendon or muscle rupture without trauma. Other features are early onset varicose veins, clubfoot, and dislocated hip at birth.',
    'VEDS is unpredictable. There is NO cure for VEDS because it is a genetic disorder. There is not a medication or treatment to manage VEDS, but there are medications and lifestyle changes patients can implement to try and reduce their risk of developing life-threatening medical events. None of this is guaranteed to prevent a complication. ',
];

export const whatWeSupportBody = [
    'Doctors and other medical professionals continue to lack awareness of VEDS because it is so rare, and they do not always come across VEDS patients. We know that awareness saves lives. Most people who have VEDS do not get diagnosed until they have a life-threatening medical complication or until they have a family member go through a complication or pass away from VEDS. Knowing that Bella has a diagnosis since she was 10 years old has saved her life multiple times.',
    'There is limited research on VEDS because it is so rare. In fact, a clinical trial for a medication to treat VEDS was suspended this year due to lack of funding.',
]