import React from 'react';

import PageWrapper from "../../components/page-wrapper/page-wrapper";

import {
    donationLink,
    emailBody,
    emailSubject,
    registrationLink,
    sponsorshipTiers,
    vedsEmail
} from "../../constants";
import SponsorshipCard from "../../components/sponsorship-card/sponsorship-card";
import {PlayerInformation} from "../../components/player-information/player-information";
import LocationMap from "../../components/location-map/location-map";

import logo from "../../img/logo.png";
import './home-page.css';

const HomePage = () => {
    return (
        <PageWrapper>
            <div className="container px-4 py-5">
                <div className="row justify-content-center">
                    <div className="col-md-4 mx-auto my-5">
                        <img className="hero-img" src={logo}/>
                    </div>
                    <div className="col-md-6 mx-auto my-5">
                        <h3 className="text-red">RESEARCH AND AWARENESS GOLF TOURNAMENT</h3>
                        <p className="my-5">
                            VEDS is a life threatening connective tissue disorder causing extremely fragile arteries and
                            internal organs.
                            Proceeds are donated to VEDS research affiliates and for VEDS related medical expenses.
                        </p>
                        <label>Bella's Mission Inc is a Texas 501(c)(3) Non-Profit Organization.</label>
                        <label>All donations are tax deductible. Tax ID: 99-4650052</label>
                        <div className="d-grid d-sm-block mx-auto my-5">
                            <a className="btn btn-lg btn-outline-danger" href={donationLink} target='_blank'>
                                Donate
                            </a>
                            <a className="btn btn-lg btn-outline-danger" href="/about-us">
                                Learn More
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-red text-white container-fluid">
                <div className="row p-5">
                    <PlayerInformation/>
                    <LocationMap/>
                </div>
            </div>
            <div className="container-fluid">
                <h1 className="text-center text-red my-4">Sponsorships</h1>
                <p className="text-center">
                    We offer several different types of sponsorship opportunity levels for individuals, teams, and businesses.
                </p>
                <div className="row text-black">
                    {sponsorshipTiers.map((tier, idx) => <SponsorshipCard key={`sponsorship-tier-${idx}`} tier={tier}/>)}
                </div>
                <div className="d-grid text-center d-sm-block mx-auto my-5">
                    <a className="btn btn-lg btn-outline-danger" href={registrationLink} target='_blank'>
                        Register Sponsorship
                    </a>
                </div>
            </div>
            <div className="bg-white container-fluid">
                <h1 className="text-red text-center my-4">Got a Question?</h1>
                <div className="row">
                    <div className="col-md-6">
                        <p className="text-center">
                            For questions about <b>registering for golf as a player, team, or sponsor,</b> please reach
                            out to our Player Coordinators:
                        </p>
                        <p className="text-center">Raul Marin - Phone: <a href='tel:915-256-8207'>915-256-8207</a></p>
                        <p className="text-center">Jerry Sanchez - Phone: <a href='tel:915-240-1508'>915-240-1508</a>
                        </p>
                    </div>
                    <div className="col-md-6">
                        <p className="text-center">
                            For questions about <b>donations and volunteering opportunities</b> please reach out to our
                            Event Coordinator Frances Marin:
                        </p>
                        <p className="text-center">
                            <a className='btn btn-outline-danger mx-2'
                               href={`mailto:${vedsEmail}?subject=${emailSubject(true)}&body=${emailBody(true)}`}>Donations</a>
                            <a className='btn btn-outline-danger mx-2'
                               href={`mailto:${vedsEmail}?subject=${emailSubject(false)}&body=${emailBody(false)}`}>Volunteering</a>
                        </p>
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};

export default HomePage;